<template>
  <div class="about">
    <Header message="About" />
    <div class="about-content">
      <p class="label-presentation">
        Mizar Alcor is established in Paris since 2020. From house to indie and
        psych rock, our goal is to gather and release an original collection of
        great music without neatly fitting into a specific category.
      </p>
      <div class="aligned-left">
        <h2>Demo submissions</h2>
        <p>
          You can send us digital demos to
          <a href="mailto:mizar.production@gmail.com"
            >mizar.production[at]gmail.com</a
          >
          or contact us for an address if you wish to send physical demos.
          <br />
          <br />
          We are all about finding new music!
        </p>
        <br>
        <h2>Concert information</h2>
        <p>
          If you want to book an artist for a concert, send us a mail at
          <a href="mailto:mizar.production@gmail.com"
            >mizar.production[at]gmail.com</a
          >. We are currently looking for opportunities to play in Paris.
          <br />
        </p>
        <div class="linkaband-ctn">
          <p>To book the band Antipodes for a concert, you can also visit our Linkaband page : </p>
          <a href='https://linkaband.com/antipodes?utm_source=badge&utm_campaign=47693' target='_blank'><img src='https://linkaband.com/assets/images/validation/reservation-noir.png' alt='Antipodes'/></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Header from "../components/Header.vue";

export default defineComponent({
  components: {
    Header,
  },
});
</script>

<style scoped lang="scss">
.about {
  .about-content {
    max-width: 40rem;
    margin: 0 auto;

    .label-presentation {
      margin-bottom: 50px;
    }

    .aligned-left {
      text-align: initial;

      a {
        color: black;
        transition: color 0.3s;

        &:hover {
          color: #1cb1b1;
        }
      }
    }
  }
}

.linkaband-ctn {
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 50px;
  }
}
</style>
